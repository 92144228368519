import React, { Fragment } from 'react';
import MaskedInput from 'react-text-mask';
import { DatepickerType } from './datepickerType';
import { MASK_DIAMES, MASK_MES_ANO, MASK_ANO, MASK_DATE, MASK_DATE_TIME } from './mask';

const CustonInputDatepicker = ({
    typeDatepicker,
    configMaskDate,
    disabled,
    openCalendarClicker,
    classes,
    ...props
}) => {

    const getMask = () => {
        switch (typeDatepicker) {
            case DatepickerType.DAY: return MASK_DIAMES;
            case DatepickerType.MONTH: return MASK_DIAMES;
            case DatepickerType.MONTH_YEAR: return MASK_MES_ANO;
            case DatepickerType.YEAR: return MASK_ANO;
            case DatepickerType.DATE: return MASK_DATE;
            case DatepickerType.DATE_TIME: return MASK_DATE_TIME;
            default: return MASK_DATE;
        }
    }

    const onClick = (event) => {
        event.preventDefault();
    }

    return (
        <>
            <div className="input-group">
                <MaskedInput
                    type="text"
                    className={classes}
                    autoComplete="off"
                    id={props.id}
                    name={props.name}
                    onBlur={props.onBlur}
                    onClick={props.onClick}
                    onFocus={props.onFocus}
                    onKeyDown={props.onKeyDown}
                    placeholder={props.placeholder}
                    readOnly={props.readOnly}
                    value={props.value}
                    disabled={disabled}
                    mask={getMask}
                    autoComplete="off"
                    onClick={onClick}
                    onChange={configMaskDate}
                />
                <div className="input-group-append input-group-append-calendar">
                    <button type="button" className="btn button-calendar btn-sm" aria-label="Escolher Data"
                        disabled={disabled}
                        onClick={openCalendarClicker}>
                        <i className="fa fa-calendar" />
                    </button>
                </div>
            </div>
        </>
    )


}

export default CustonInputDatepicker;