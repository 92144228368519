export const DatepickerType = {
    YEAR: 'yyyy',
    MONTH: 'MM',
    DAY: 'dd',
    MONTH_YEAR: 'MM/yyyy',
    DATE: 'dd/MM/yyyy',
    DATE_TIME: 'dd/MM/yyyy HH:mm:ss'
}

export const DatepickerPlaceholder = {
    YEAR: 'aaaa',
    MONTH: 'mm',
    DAY: 'dd',
    MONTH_YEAR: 'mm/aaaa',
    DATE: 'dd/mm/aaaa',
    DATE_TIME: 'dd/MM/aaaa h:mm:ss'
}