import React from 'react';
import Modal from 'gp-modal';
import './styles.scss'

const ModalDetalheAuditoria = ({ show, closeModal, dados }) => {

    return (
        <Modal title='Detalhes da Auditoria' showModal={show} closeModal={closeModal}>
            <div className="modal-body">
                <div className="painel-json">
                    <pre> {JSON.stringify(dados, null, 2)} </pre>
                </div>
            </div>
        </Modal>
    )
}

export default ModalDetalheAuditoria;