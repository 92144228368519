import React, { useState } from 'react';

import './styles.scss';

import DatePicker, { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import { useEffect } from 'react';
import MessageError from 'gp-message-error';
import { DatepickerType, DatepickerPlaceholder } from './datepickerType';
import If from 'gp-if';
import { isValid } from 'date-fns';
import HeaderDatepicker from './headerDatepicker';
import CustonInputDatepicker from './customInputDatepicker';
import { removeMask, applyMaskAnoMesDia, applyMaskAnoMesDiaHora, applyMaskData } from './mask';
import { parseISO } from 'date-fns/esm';
registerLocale('pt', pt);

export const Datepicker = ({
    id,
    name,
    date,
    onChange,
    label,
    errors = [],
    classes = '',
    callback,
    typeDatepicker = DatepickerType.DATE,
    disabled = false,
    onBlur,
    isTime = false,
    handleOnBlur = () => { } }
) => {
    const [startDate, setStartDate] = useState(date);
    useEffect(() => {
;        setStartDate(date);
    }, [date]);

    const obterClassNameDay = (date) => {
        if (startDate !== undefined && startDate !== null) {
            return startDate.getDate() === date.getDate() && startDate.getMonth() === date.getMonth() ? "datepicker" : "day-datepicker"
        } else {
            return "day-datepicker";
        }
    }

    const initialIsShowMonth = () => {
        return typeDatepicker === DatepickerType.MONTH || typeDatepicker === DatepickerType.MONTH_YEAR;
    }

    const initialIsShowYear = () => {
        return typeDatepicker === DatepickerType.YEAR;
    }

    const months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
    ];
    const [isShowMonth, setIsShowMonth] = useState(initialIsShowMonth);
    const [isShowYear, setIsShowYear] = useState(initialIsShowYear);
    const [refCalendar, setRefCalendar] = useState(false);

    const openCalendarMonth = () => {
        if (DatepickerType.DATE === typeDatepicker || DatepickerType.MONTH_YEAR === typeDatepicker || DatepickerType.DATE_TIME === typeDatepicker) {
            if (isShowYear) {
                setIsShowYear(false);
            }
            if (isShowMonth) {
                setIsShowYear(true);
            }

            setIsShowMonth(!isShowMonth);
        }
    }

    const openChangeCalendar = (date) => {
        const data = isValid(date) ? date : undefined;
        setStartDate(data);
        if (callback) {
            callback(data);
        } else if (data !== startDate) {
            onChange({ target: { name, value: data } });
        }

        if (DatepickerType.DATE === typeDatepicker || DatepickerType.MONTH_YEAR === typeDatepicker || DatepickerType.DATE_TIME === typeDatepicker) {
            if (isShowMonth && DatepickerType.MONTH_YEAR !== typeDatepicker) {
                setIsShowMonth(false);
            }

            if (isShowYear) {
                setIsShowYear(false);
                setIsShowMonth(true);
            }
        }

        if ((DatepickerType.MONTH_YEAR === typeDatepicker && isShowMonth) ||
            (DatepickerType.YEAR === typeDatepicker && isShowYear)) {
            closeCalendar();
        }
    }

    const getPlaceholder = () => {
        if (typeDatepicker === DatepickerType.DAY) {
            return DatepickerPlaceholder.DAY;
        } else if (typeDatepicker === DatepickerType.MONTH) {
            return DatepickerPlaceholder.MONTH;
        } else if (typeDatepicker === DatepickerType.MONTH_YEAR) {
            return DatepickerPlaceholder.MONTH_YEAR;
        } else if (typeDatepicker === DatepickerType.YEAR) {
            return DatepickerPlaceholder.YEAR;
        } else if (typeDatepicker === DatepickerType.DATE) {
            return DatepickerPlaceholder.DATE;
        } else if (typeDatepicker === DatepickerType.DATE_TIME) {
            return DatepickerPlaceholder.DATE_TIME;
        }
    }

    const setValoresValida = (value) => {
        const formatoIso = applyMaskAnoMesDia(value);
        let valueTipoDate = parseISO(formatoIso);

        if (valueTipoDate instanceof Date && isValid(valueTipoDate)) {
            setStartDate(valueTipoDate);
        } else {
            valueTipoDate = undefined;
            setStartDate(undefined);
        }

        if (callback) {
            callback(valueTipoDate);
        } else if (valueTipoDate !== startDate) {
            onChange({ target: { name, value: valueTipoDate } });
        }
    }

    const configMaskDate = (event) => {
        let value = event.target.value;
        let valueSemMascara = removeMask(value);

        if (typeDatepicker === DatepickerType.MONTH_YEAR && valueSemMascara && valueSemMascara.length === 6) {
            valueSemMascara = '01' + valueSemMascara;
            value = '01/' + value;
        } else if (typeDatepicker === DatepickerType.YEAR && valueSemMascara && valueSemMascara.length === 4) {
            valueSemMascara = '0101' + valueSemMascara;
            value = '01/01/' + value;
        }

        if (valueSemMascara && valueSemMascara.length === 8 && (
            typeDatepicker === DatepickerType.DATE ||
            typeDatepicker === DatepickerType.MONTH_YEAR ||
            typeDatepicker === DatepickerType.YEAR)) {

            setValoresValida(value);

        } else if (valueSemMascara && valueSemMascara.length === 14 && typeDatepicker === DatepickerType.DATE_TIME) {
            const formatoIso = applyMaskAnoMesDiaHora(value);
            let valueTipoDateTime = parseISO(formatoIso);

            if (valueTipoDateTime instanceof Date && isValid(valueTipoDateTime)) {
                setStartDate(valueTipoDateTime);
            } else {
                valueTipoDateTime = undefined;
                setStartDate(undefined);
            }

            if (callback) {
                callback(valueTipoDateTime);
            } else if (valueTipoDateTime !== startDate) {
                onChange({ target: { name, value: valueTipoDateTime } });
            }
        } else if (!valueSemMascara) {
            setStartDate(undefined);
            callback(undefined);
        }
    }

    const closeCalendar = () => {
        refCalendar.setOpen(false)
    }

    const openCalendarClicker = (event) => {
        refCalendar.setFocus(true);
        refCalendar.setOpen(true);
        if (typeDatepicker === DatepickerType.DATE || typeDatepicker === DatepickerType.DATE_TIME) {
            setIsShowMonth(false);
            setIsShowYear(false);
        }
    }

    const onCalendarClose = () => {
        if (handleOnBlur) {
            handleOnBlur(startDate);
        }
    }

    const getShouldCloseOnSelect = () => {
        return (!isShowMonth && !isShowYear)
            || DatepickerType.YEAR === typeDatepicker || (DatepickerType.MONTH_YEAR === typeDatepicker && isShowMonth);
    }

    const blur = (event) => {
        if (onBlur) {
            onBlur(event);
        }
        if (handleOnBlur) {
            handleOnBlur(startDate);
        }
    }

    return (
        <div className={classes + " form-group input-group-sm"}>
            {
                label && <label htmlFor={id} className='col-form-label'>{label}</label>
            }

            <div className="input-group">
                <If test={typeDatepicker === DatepickerType.DATE || 
                    typeDatepicker === DatepickerType.DAY || 
                    typeDatepicker === DatepickerType.YEAR || 
                    typeDatepicker === DatepickerType.DATE_TIME ||
                    typeDatepicker === DatepickerType.MONTH_YEAR}>
                    <DatePicker id={id} name={id}
                        className={`form-control datepicker-form-control ${errors[id] && "is-invalid"}`}
                        aria-modal="true"
                        selected={startDate}
                        onChange={openChangeCalendar}
                        onBlur={blur}
                        onCalendarClose={onCalendarClose}
                        chooseDayAriaLabelPrefix='Escolha'
                        showFullMonthYearPicker={true}
                        preventOpenOnFocus={true}
                        dateFormat={typeDatepicker}
                        placeholderText={getPlaceholder()}
                        locale="pt"
                        disabled={disabled}
                        enableTabLoop={true}
                        ref={setRefCalendar}
                        disabledKeyboardNavigation={false}
                        tabIndex={0}
                        weekDayClassName={() => "week-datepicker"}
                        autoComplete="off"
                        popperPlacement={'bottom-end'}
                        value={startDate}
                        customInput={
                            <CustonInputDatepicker
                                classes={`form-control datepicker-form-control ${errors[id] && "is-invalid"}`}
                                configMaskDate={configMaskDate}
                                disabled={disabled}
                                openCalendarClicker={openCalendarClicker}
                                typeDatepicker={typeDatepicker}
                            />
                        }
                        showMonthYearPicker={isShowMonth}
                        shouldCloseOnSelect={getShouldCloseOnSelect()}
                        showYearPicker={isShowYear}
                        showTimeInput={isTime}
                        timeInputLabel="Hora:"
                        renderCustomHeader={({
                            date,
                            decreaseMonth,
                            increaseMonth,
                            decreaseYear,
                            increaseYear,
                            nextYearButtonDisabled,
                            prevYearButtonDisabled,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled
                        }) =>
                            <HeaderDatepicker
                                date={date}
                                decreaseMonth={decreaseMonth}
                                increaseMonth={increaseMonth}
                                decreaseYear={decreaseYear}
                                increaseYear={increaseYear}
                                prevMonthButtonDisabled={prevMonthButtonDisabled}
                                nextMonthButtonDisabled={nextMonthButtonDisabled}
                                nextYearButtonDisabled={nextYearButtonDisabled}
                                prevYearButtonDisabled={prevYearButtonDisabled}
                                typeDatepicker={typeDatepicker}
                                openCalendarMonth={openCalendarMonth}
                                openClose={openChangeCalendar}
                                isShowMonth={isShowMonth}
                                isShowYear={isShowYear}
                                closeCalendar={closeCalendar}
                                refCalendar={refCalendar}
                                months={months}
                            />
                        }
                        dayClassName={date => obterClassNameDay(date)} ></DatePicker>
                </If>
            </div>


            <MessageError error={errors[id]} />
        </div >
    )
}
