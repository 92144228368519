export const TypeOperator = {
    DEFAULT: 'EQ',
    ENTRE: 'BT',
    CONTEM: 'LK',
    NAO_CONTEM: 'NLK',
    MAIOR_QUE: 'GT',
    MAIOR_OU_IGUAL_QUE: 'GE',
    MENOR_OU_IGUAL_QUE: 'LE',
    MENOR_QUE: 'LT',
    IGUAL: 'EQ',
    DIFERENTE: 'DF',
    TODOS: 'LK',
}

// BT = quando tiver dois valores entre: valueIni - valueEnd (serão setados)
// LK = Like (contem)
// NLK = Não Like (Não contem)
// GT = Maior que
// GE = Maior ou igual que
// LE = menor ou igual que
// LT = menor que;