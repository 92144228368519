import React, { useMemo, Fragment, useState, useEffect } from 'react'
import { useTable, usePagination, useSortBy, useFilters, useAsyncDebounce, } from 'react-table'
import { AlignType } from './alignType';
import axios from 'axios';
import { TypeOperator } from './typeOperator';
import { TypeFilter } from './typeFilter';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import './styles.scss'

import FormFiltro from 'gp-form-filtro';
import Pagination from 'gp-pagination';
import ModalConfirm from 'gp-modal-confirm';
import If from 'gp-if';
import Painel from 'gp-painel';
import 'gp-pagination/dist/index.css';
import 'gp-painel/dist/index.css';

export const MASK_NUMBER = (limitInteger = null) => {
    return createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        allowDecimal: false,
        integerLimit: limitInteger,
        allowNegative: false,
        allowLeadingZeroes: true,
        requireDecimal: false
    });
}

const DefaultColumnFilter = ({
    column: { filterValue = [], setFilter, Header, filter }
}) => {
    const onChange = (event, isNumber = false) => {
        event.preventDefault();

        const value = event.target.value;
        const minLength = filter && filter.minLength ? filter.minLength : undefined;
        const operator = filter && filter.operator ? filter.operator : TypeOperator.DEFAULT;
        const tipo = filter && filter.tipo ? filter.tipo : TypeFilter.DEFAULT;

        if (filter && (minLength || operator || tipo)) {

            if (isNumber) {
                const valueFilterApi = filter && filter.valueFilterApi ? filter.valueFilterApi : undefined;
                setFilter((old = []) => [value, minLength, operator, tipo, valueFilterApi])
            } else {
                setFilter((old = []) => [value, minLength, operator, tipo]);
            }
        } else {
            setFilter(value || undefined);
        }
    }

    return (
        <>
            {(filter && filter.tipo !== TypeFilter.NUMBER) &&
                <input
                    type="text"
                    className="form-control input-sm form-table"
                    value={filter && (filter.minLength || filter.operator || filter.tipo) ? (filterValue[0] || '') : (filterValue || '')}
                    onChange={onChange}
                // placeholder={Header}
                />
            }
            {filter && filter.tipo === TypeFilter.NUMBER && <MaskedInput
                className="form-control input-sm form-table"
                guide={false}
                mask={filter.mask ? filter.mask : MASK_NUMBER()}
                // placeholder={Header}
                onChange={e => onChange(e, true)}
                value={filter && (filter.minLength || filter.operator || filter.tipo) ? (filterValue[0] || '') : (filterValue || '')}
                autoComplete="off" />}
        </>
    )
}

const ColumnActions = (editAction, openModal, deleteAction, hasPermissionsAction, hasPermissionEditar, hasPermissionExcluir, editConditions, deleteConditions, hasPermission) => {


    const getTypeString = (condition) => typeof condition === 'string';

    if (hasPermission({ hasAnyRole: hasPermissionsAction })) {
        return {
            Header: 'Ações',
            accessor: 'id',
            disableFilters: true,
            disableSortBy: true,
            isButtonResetAllFilters: true,
            width: '10%',
            Cell: ({ row, value }) => {
                const conditionEdit = getTypeString(editConditions) ? row.original[editConditions] : editConditions;
                const conditionDelete = getTypeString(deleteConditions) ? row.original[deleteConditions] : deleteConditions;

                return (<div className="cell-action text-center">
                    {editAction && hasPermission({ hasAnyRole: hasPermissionEditar }) && conditionEdit &&
                        <button type="button" className="btn btn-outline-success btn-sm" title="Editar"
                            onClick={(event) => editAction(event, value)}>
                            <i className="fas fa-edit" />
                        </button>}
                    {deleteAction && hasPermission({ hasAnyRole: hasPermissionExcluir }) && conditionDelete &&
                        <button type="button" className="btn btn-outline-danger btn-sm ml-2" title="Excluir"
                            onClick={(event) => openModal(event, value)}>
                            <i className="fa fa-trash" />
                        </button>}
                </div>)
            }
        }
    } else {
        return {
            Header: 'Id',
            accessor: 'id',
            disableFilters: true,
            disableSortBy: true,
            isVisible: false
        }
    }
}

export const Table = ({
    columns,
    data = [],
    editAction,
    deleteAction,
    editConditions = true,
    deleteConditions = true,
    initialPage = 0,
    url,
    isPaginationMemory = false,
    isFilterTable = false,
    hasPermissionsAction,
    hasPermissionEditar,
    hasPermissionExcluir,
    alignPagination = AlignType.CENTER,
    isExibePainel = false,
    tituloPainelFiltros,
    tituloPainelTable,
    tituloPage,
    isFormFilter = false,
    filtroLista = {},
    updateTable = false,
    resetTable = false,
    selectedField = false,
    hasPermission,
    message
}) => {
    const defaultColumn = useMemo(
        () => ({
            Filter: isFilterTable ? DefaultColumnFilter : '',
        }),
        [] // eslint-disable-line
    );
    const itensPerPage = [5, 10, 20, 30, 40, 50];
    const [showModal, setShowModal] = useState(false);
    const [controlledPageCount, setControlledPageCount] = useState(isPaginationMemory ? data.length : 0);
    const [controlledData, setControlledData] = useState(data);
    const [item, setItem] = useState({});
    const [resetFilterNotExecuted, setResetFilterNotExecuted] = useState(false);

    useEffect(() => {
        if (data.length > 0) {
            setControlledPageCount(data.length);
            setControlledData(data);
        }
    }, [data])

    const openModal = (event, item) => {
        if (event) {
            event.preventDefault();
        }
        setShowModal(true);
        setItem(item);
    };

    const closeModal = () => {
        setShowModal(false);
        setItem({});
    };

    if (deleteAction || editAction) {
        let existe = false;
        columns.forEach(column => {
            if (column.Header === 'Ações' && column.accessor === 'id') {
                existe = true;
            }
        });
        if (!existe) {
            let columnAction = // eslint-disable-line
                columns.push(
                    ColumnActions(
                        editAction,
                        openModal,
                        deleteAction,
                        hasPermissionsAction,
                        hasPermissionEditar,
                        hasPermissionExcluir,
                        editConditions,
                        deleteConditions,
                        hasPermission
                    ));
        }
    }



    const onPesquisar = (filtros, fieldsVisible) => {
        if (selectedField) {
            definirColumns(fieldsVisible);
        }
        setResetFilterNotExecuted(false);
        let searchQueries = [];
        filtros.forEach((filtro) => {
            let searchQuery = {
                operator: filtro.operator,
                field: filtro.field,
                fieldType: filtro.tipo
            }

            if (filtro.operator === TypeOperator.ENTRE || filtro.tipo === TypeFilter.DATE) {
                searchQuery.valueIni = filtro.valueIni;
                searchQuery.valueEnd = filtro.valueEnd;
            } else {
                searchQuery.value = filtro.value;
            }

            searchQueries.push(searchQuery);
        });

        obterLista(initialPage, pageSize, url, sortBy, searchQueries, true);
    }

    const definirColumns = (fieldsVisible) => {
        columns.map(column => {
            if (column.Header) {
                column.isVisible = fieldsVisible ? hasField(fieldsVisible, column.Header) : true;
            }
            return column;
        });

        setHiddenColumns(columns.filter(column => column.isVisible !== undefined ? !column.isVisible : false).map(column => column.accessor))
    }

    const hasField = (fieldsVisible, filter) => {
        let isVisible = false;
        fieldsVisible.forEach(field => {
            if (filter === field) {
                isVisible = true;
            }
        });
        return isVisible;
    }

    const hasPodePesquisar = (filter) => {
        if (filter.value[0] === '') {
            return true;
        }
        if (filter.value[0].length < filter.value[1]) {
            return false;
        }
        return true;
    }

    const obterLista = (pageIndex, pageSize, url, sorted, filtros = [], resetFilter) => {

        const reset = resetFilter ? resetFilter : !resetFilterNotExecuted;

        if (url && reset) {
            const pagination = {
                page: pageIndex,
                size: pageSize,
            }

            let searchQueries = [];
            let isPodePesquisar = [];

            if (filtros.length > 0) {
                setResetFilterNotExecuted(true);
                searchQueries = filtros;
                setAllFilters([]);
            } else if (filters.length > 0) {
                setResetFilterNotExecuted(false);
                let valor = undefined;
                let operator = undefined;
                let tipo = undefined;

                filters.forEach(filter => {
                    if (Array.isArray(filter.value)) {
                        isPodePesquisar.push(hasPodePesquisar(filter));
                        const valueFilterApi = filter.value[4];
                        valor = valueFilterApi ? valueFilterApi(filter.value[0]) : filter.value[0];
                        operator = filter.value[2] ? filter.value[2] : TypeOperator.DEFAULT;
                        tipo = filter.value[3] ? filter.value[3] : TypeFilter.DEFAULT;
                    } else {
                        valor = filter.value;
                        operator = TypeOperator.DEFAULT;
                        tipo = TypeFilter.DEFAULT;
                    }

                    if (valor || (valor && valor !== '')) {
                        searchQueries.push({
                            operator: operator,
                            value: valor,
                            valueIni: null,
                            valueEnd: null,
                            field: filter.id,
                            fieldType: tipo
                        });
                    }
                });
            }

            if (sorted.length > 0) {
                const tipoSorted = sorted && sorted[0].desc ? 'desc' : 'asc';
                pagination.sort = sorted[0].id + ',' + tipoSorted;
            }

            if (isPodePesquisar.length === 0 || isPodePesquisar.includes(true)) {
                const paramsRequisicao = {
                    pagination: pagination,
                    searchQueries: searchQueries
                }
                if (filtroLista) {
                    paramsRequisicao.searchQueries.push(filtroLista);
                }

                let params = { ...paramsRequisicao };
                getLista(url, params);
            }
        } else {
            setResetFilterNotExecuted(false);
        }
    }

    const getLista = (url, params) => {
        axios.post(url, params).then((response) => {
            const dados = response ? response.data : response;
            if (dados) {
                setControlledPageCount(dados.totalPages);
                setControlledData(dados.content);
            }
        });
    }




    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setAllFilters,
        setHiddenColumns,
        state: { pageIndex, pageSize, sortBy, filters },
    } = useTable({
        columns,
        data: controlledData,
        initialState: { pageIndex: initialPage },
        manualPagination: !isPaginationMemory,
        manualSortBy: !isPaginationMemory,
        manualFilters: !isPaginationMemory,
        pageCount: controlledPageCount,
        autoResetPage: false,
        disableMultiSort: true,
        defaultColumn,
    }, useFilters, useSortBy, usePagination);

    const onFetchDataDebounce = useAsyncDebounce(obterLista, 100);

    useEffect(() => {
        if (!isPaginationMemory) {
            onFetchDataDebounce(pageIndex, pageSize, url, sortBy);
        }
    }, [onFetchDataDebounce, pageIndex, pageSize, url, sortBy, filters]); // eslint-disable-line

    useEffect(() => {
        setHiddenColumns(columns.filter(column => column.isVisible !== undefined ? !column.isVisible : false).map(column => column.accessor))
    }, [setHiddenColumns, columns]);

    useEffect(() => {
        if (resetTable) {
            setControlledPageCount(0);
            setControlledData([]);
        }
    }, [resetTable]); 

    useEffect(() => {
        if (updateTable) {
            obterLista(pageIndex, pageSize, url, sortBy);
        }
    }, [updateTable]); // eslint-disable-line

    const getClassAlignPagination = () => {
        switch (alignPagination) {
            case AlignType.CENTER: return 'pagination-center';
            case AlignType.LEFT: return 'pagination-left';
            case AlignType.RIGTH: return 'pagination-right';
            default: return AlignType.DEFAULT;
        }
    };

    const getClassTd = (align) => {
        switch (align) {
            case AlignType.LEFT: return 'text-left';
            case AlignType.RIGTH: return 'text-right';
            case AlignType.CENTER: return 'text-center';
            default: return '';
        }
    };

    const resetFilters = () => setAllFilters([]);

    const actionDelete = async (item) => {
        // if (event) {
        // event.preventDefault();
        // event.persist();
        // }
        if (deleteAction) {
            await deleteAction(item, obterLista, initialPage, pageSize, url, sortBy);

            closeModal();
        }
    }

    return (
        <>
            <If test={isFormFilter}>
                <Painel isExibe={isExibePainel} titulo={tituloPage} subTitulo={tituloPainelFiltros}>
                    <FormFiltro
                        columns={columns}
                        onPesquisar={onPesquisar}
                        // classes={'d-flex justify-content-end'}
                        selectedField={selectedField}
                    />
                </Painel>
            </If>

            <Painel isExibe={isExibePainel} subTitulo={tituloPainelTable}>
                <div>
                    <div className="view form-group row mr-0">
                        <label htmlFor="visualizar" className="text-right col-md-5 col-form-label">Visualizar</label>
                        <select
                            id="visualizar"
                            className="custom-select col-2 col-md-1"
                            value={pageSize}
                            onChange={e => { setPageSize(Number(e.target.value)) }} >
                            {itensPerPage.map(pageSize => (
                                <option key={pageSize} value={pageSize}>{pageSize}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="table-responsive">
                    <table {...getTableProps()} className="table table-sm table-bordered table-align table-striped ">
                        <thead className="thead-light">
                            {headerGroups.map((headerGroup, index) => (
                                <Fragment key={index}>
                                    <tr {...headerGroup.getHeaderGroupProps()} key={1}>
                                        {headerGroup.headers.map((column) => (

                                            <th className="text-center"
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                width={column.width ? column.width : ''}
                                                // minWidth={column.minWidth ? column.minWidth : ''}
                                                scope="col">
                                                {column.render("Header")}
                                                {column.isSorted ?
                                                    column.isSortedDesc ?
                                                        <i className="fas fa-caret-up"></i> :
                                                        <i className="fas fa-caret-down"></i> : ''}
                                            </th>
                                        ))}
                                    </tr>
                                    {isFilterTable && <tr {...headerGroup.getHeaderGroupProps()} key={2}>
                                        {headerGroup.headers.map((column) => (
                                            <th {...column.getHeaderProps()} className="text-center"
                                                scope="col">
                                                {column.canFilter ? column.render('Filter') : null}
                                                {column.isButtonResetAllFilters ?
                                                    <button type="button" className="btn btn-outline-dark btn-sm btn-reset" title="Limpar Filtros"
                                                        onClick={resetFilters}>
                                                        <i className="fas fa-eraser" />
                                                    </button> : null}
                                            </th>
                                        ))}
                                    </tr>}

                                </Fragment>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.length > 0 ? page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()} className={getClassTd(cell.column.alignItems)}> {cell.render("Cell")}</td>;
                                        })}
                                    </tr>
                                );
                            }) : <tr><td colSpan={columns.length} className="noResult"> Nenhum registro encontrado</td></tr>}

                        </tbody>
                    </table>
                </div>

                <div className={'pagination-table ' + getClassAlignPagination()}>
                    <Pagination
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        gotoPage={gotoPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                    />
                </div>

            </Painel>

            <ModalConfirm
                show={showModal}
                actionClose={closeModal}
                message={message.CONFIRMACAO_EXCLUSAO}
                title={message.TITLE_CONFIRMACAO}
                actionConfirm={() => actionDelete(item)} />
        </>
    );
};