import React, { Fragment } from 'react';
import { getYearsPeriod } from './mask';
import { DatepickerType } from './datepickerType';
import { subYears, addYears } from 'date-fns';
import If from 'gp-if';
import { subMonths, addMonths } from 'date-fns/esm';


const HeaderDatepicker = ({
    date,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    nextYearButtonDisabled,
    prevYearButtonDisabled,
    typeDatepicker,
    openCalendarMonth,
    isShowMonth,
    isShowYear,
    closeCalendar,
    refCalendar,
    months,
    openClose
}) => {
    const { inicioPeriodo, fimPeriodo } = getYearsPeriod(date);
    let dataSelecionada = date ? date : new Date();
    let hasNotSelecion = false;

    const onFocusClose = () => {
        let ariaLabel;
        if (isShowYear) {
            ariaLabel = 'Escolha o ano ';
            configFocus('react-datepicker__year-container-text--selected', 'react-datepicker__year-container-text', onFocusAno, false, ariaLabel);
        }
        if (isShowMonth) {
            ariaLabel = 'Escolha o mês ';
            configFocus('react-datepicker__month--selected', 'react-datepicker__month-text', onFocusMonth, false, ariaLabel);
        }
    }

    const configFocus = (classSelected, classes, callbackOnFocus, isFocus = false, ariaLabel = '') => {
        let elemento = document.getElementsByClassName(classSelected);
        let elementoAll = document.getElementsByClassName(classes);
        if (elemento.length > 0) {
            hasNotSelecion = false;
            for (let i = 0; i < elementoAll.length; i++) {
                elementoAll[i].tabIndex = -1;
                elementoAll[i].setAttribute('aria-label', ariaLabel + elementoAll[i].textContent);
            }
            if (isFocus) {
                elemento[0].focus();
            }
            elemento[0].tabIndex = 0;
            elemento[0].addEventListener('keydown', callbackOnFocus);
        } else {
            if (elementoAll.length > 0) {
                const index = dataSelecionada.getMonth();
                hasNotSelecion = true;
                elementoAll[index].tabIndex = 0;
                elementoAll[index].addEventListener('keydown', callbackOnFocus);
                elementoAll[index].classList.add("react-datepicker__month--selected");
            }
        }
    }

    const selecionarMes = () => {
        let ariaLabel = 'Escolha o mês ';
        configFocus('react-datepicker__month--selected', 'react-datepicker__month-text', onFocusMonth, true, ariaLabel);
    }

    const selecionarAno = () => {
        let ariaLabel = 'Escolha o ano ';
        configFocus('react-datepicker__year-container-text--selected', 'react-datepicker__year-container-text', onFocusAno, true, ariaLabel);
    }

    const onFocusMonth = (event) => {
        const key = event.key;
        let isTab = false;

        let irParaMonth;

        switch (key) {
            case "ArrowLeft":
                irParaMonth = subMonths(dataSelecionada, 1);
                break;
            case "ArrowRight":
                irParaMonth = addMonths(dataSelecionada, 1);
                break;
            case "ArrowUp":
                irParaMonth = subMonths(dataSelecionada, 3);
                break;
            case "ArrowDown":
                irParaMonth = addMonths(dataSelecionada, 3);
                break;
            case "PageUp":
                irParaMonth = subMonths(dataSelecionada, 12);
                break;
            case "PageDown":
                irParaMonth = addMonths(dataSelecionada, 12);
                break;
            case "Tab":
                const index = dataSelecionada ? dataSelecionada.getMonth() : 0;
                removerClassMonth(index);
                const elementoButton = document.getElementsByClassName('button-close');
                elementoButton[0].focus();
                isTab = true;
                break;
            case "Enter":
                openClose(dataSelecionada);
                isTab = true;
                break;
            default:
                break;
        }
        if (!isTab) {
            refCalendar.setPreSelection(irParaMonth);
            let index = dataSelecionada ? dataSelecionada.getMonth() : 0;
            dataSelecionada = irParaMonth;
            if (hasNotSelecion) {
                let elementoAll = removerClassMonth(index);
                index = dataSelecionada.getMonth();
                elementoAll[index].classList.add("react-datepicker__month--selected");
                elementoAll[index].focus();
                elementoAll[index].addEventListener('keydown', onFocusMonth);
                elementoAll[index].tabIndex = 0;

            } else {
                selecionarMes();
            }
        }
        event.preventDefault();

    }

    const removerClassMonth = (index) => {
        return removerClassSelected(index, 'react-datepicker__month-text', 'react-datepicker__month--selected');
    }

    const removerClassSelected = (index, classe, classesSelect) => {
        let elementoAll = document.getElementsByClassName(classe);
        elementoAll[index].classList.remove(classesSelect);
        elementoAll[index].tabIndex = -1;

        return elementoAll;
    }

    const onFocusAno = (event) => {
        const key = event.key;
        let isTab = false;

        let irParaAno;

        switch (key) {
            case "ArrowLeft":
                irParaAno = subYears(dataSelecionada, 1);
                break;
            case "ArrowRight":
                irParaAno = addYears(dataSelecionada, 1);
                break;
            case "ArrowUp":
                irParaAno = subYears(dataSelecionada, 3);
                break;
            case "ArrowDown":
                irParaAno = addYears(dataSelecionada, 3);
                break;
            case "PageUp":
                irParaAno = subYears(dataSelecionada, 12);
                break;
            case "PageDown":
                irParaAno = addYears(dataSelecionada, 12);
                break;
            case "Tab":
                let elementoButton = document.getElementsByClassName('button-close');
                elementoButton[0].focus();
                isTab = true;
                break;
            case "Enter":
                openClose(dataSelecionada);
                isTab = true;
                break;
            default:
                break;

        }
        if (!isTab) {
            refCalendar.setPreSelection(irParaAno);
            dataSelecionada = irParaAno;
            selecionarAno();
        }
        event.preventDefault();
    }

    const customHeader = () => {
        if (DatepickerType.DATE === typeDatepicker || DatepickerType.MONTH_YEAR === typeDatepicker) {
            return (
                <div className='datepicker-header'
                    role="dialog"
                    aria-modal="true"
                >
                    <div className="datepicker-close">
                        <button type="button" className='button-close' aria-label="Fechar Calendário"
                            onClick={closeCalendar}
                            autoFocus={true}
                            onFocus={onFocusClose}>
                            <i className="fa fa-times" />
                        </button>
                    </div>
                    <div className="datepicker-header-navegacao">
                        <If test={!isShowYear}>
                            <button type="button" className='button-month button--previous'
                                aria-label="Mês Anterior"
                                onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                Mês Anterior
                            </button>
                        </If>

                        <If test={isShowYear}>
                            <button type="button" className='button-month button--previous'
                                aria-label="Período Anterior"
                                onClick={decreaseYear} disabled={prevYearButtonDisabled}>
                                Período Anterior
                            </button>
                        </If>

                        <button type="button" className='button-year' onClick={openCalendarMonth}
                            aria-live="polite"
                            aria-label={isShowMonth ? "Escolher Ano" : "Escolher Mês"}>
                            {!isShowMonth && !isShowYear && months[date.getMonth()]}
                            {isShowYear ? inicioPeriodo + ' a ' + fimPeriodo : ' ' + date.getFullYear()}
                        </button>

                        <If test={!isShowYear}>
                            <button type="button" className='button-month button--next'
                                aria-label="Próximo Mês"
                                onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                Próximo Mês
                            </button>
                        </If>
                        <If test={isShowYear}>
                            <button type="button" className='button-month button--next'
                                aria-label="Próximo Período"
                                onClick={increaseYear} disabled={nextYearButtonDisabled}>
                                Próximo Período
                            </button>
                        </If>


                    </div>
                </div>
            )
        } else if (DatepickerType.YEAR === typeDatepicker) {
            return (
                <div className='datepicker-header'
                    role="dialog"
                    aria-modal="true">
                    <div className="datepicker-close">
                        <button type="button" className='button-close' aria-label="Fechar Calendário"
                            onClick={closeCalendar}
                            autoFocus={true}
                            onFocus={onFocusClose}>
                            <i className="fa fa-times" />
                        </button>
                    </div>
                    <div className="datepicker-header-navegacao">
                        <button type="button" className='button-month button--previous'
                            aria-label="Período Anterior"
                            onClick={decreaseYear} disabled={prevYearButtonDisabled}>
                            Período Anterior
                            </button>
                        <button type="button" className='button-year'
                            aria-live="polite"
                            aria-label={"Escolher Ano"}>
                            {inicioPeriodo + ' a ' + fimPeriodo}
                        </button>
                        <button type="button" className='button-month button--next'
                            aria-label="Próximo Período"
                            onClick={increaseYear} disabled={nextYearButtonDisabled}>
                            Próximo Período
                            </button>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            {customHeader()}
        </>
    )


}

export default HeaderDatepicker;