import { getYear } from 'date-fns';

export const MASK_DIAMES = [/\d/, /\d/]
export const MASK_MES_ANO = [/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
export const MASK_DATE = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
export const MASK_DATE_TIME = [ /[0-9]/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]
export const MASK_ANO = [/\d/, /\d/, /\d/, /\d/]

export const removeMask = value => { return value !== '' ? value.replace(/[^0-9]/g, '') : value }

export const applyMaskAnoMesDia = (dataString) => {
    let separador = '-';
    dataString = removeMask(dataString);
    dataString = dataString.replace(/(\d{2})(\d{2})(\d{4})/, "$3" + separador + "$2" + separador + "$1");
    return dataString;
}

export const applyMaskData = (dataTimeString) => {
    dataTimeString = removeMask(dataTimeString);
    dataTimeString =  dataTimeString.slice(0,8);
    dataTimeString = dataTimeString.replace(/(\d{2})(\d{2})(\d{4})/, "$3" + "-" + "$2" + "-" + "$1");
    return dataTimeString;
}

export const applyMaskAnoMesDiaHora = (dataString) => {
    console.log('dataString', dataString);
    let separador = '-';
    dataString = removeMask(dataString);
    console.log('removeMask', dataString);
    dataString = dataString.replace(/(\d{2})(\d{2})(\d{4})(\d{2})(\d{2})(\d{2})/, "$3" + separador + "$2" + separador + "$1" + "T" + "$4" + ":" + "$5" + ":" + "$6");
    console.log('dataStr', dataString);
    return dataString;
}
// 2020-11-17 23:59:59
export const getYearsPeriod = (date) => {
    const fimPeriodo = getYear(date);
    const inicioPeriodo = fimPeriodo - 11;
    return { inicioPeriodo, fimPeriodo };
}