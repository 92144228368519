import React, { useState, Fragment, useEffect } from 'react';
import If from 'gp-if';
import { TypeFilter } from './typeFilter';
import Datepicker, { DatepickerType } from 'gp-datepicker';
import { TypeOperator } from './typeOperator';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {SelectField} from './selectField';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import 'gp-datepicker/dist/index.css';
import './styles.scss';

export const MASK_NUMBER = (limitInteger = null) => {
    return createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        allowDecimal: false,
        integerLimit: limitInteger,
        allowNegative: false,
        allowLeadingZeroes: true,
        requireDecimal: false
    });
}

export const FormFiltro = ({ columns, onPesquisar, classes, selectedField = false }) => {
    const [filtros, setFiltros] = useState([]);
    let filterDefaults = columns[0].filter;
    if (filterDefaults) {
        filterDefaults.id = columns[0].accessor;
    }
    const [filtro, setFiltro] = useState(filterDefaults);
    const [hasChangeTipo, setHasChangeTipo] = useState(false);
    const [isMostraPicklist, setMostraPicklist] = useState(false);

    const adicionarFiltro = () => {
        if (!hasFiltro(filtros, filtro)) {
            filtro.checked = true;
            const filter = procurarFilter(filtro.id);
            filtro.mask = filter ? filter.mask : null;
            filtro.valueFilterApi = filter ? filter.valueFilterApi : null;
            filtro.operator = getOperatorDefault(filtro.tipo);
            setFiltros([...filtros, filtro]);
        }
    }

    const procurarFilter = (id) => {
        const lista = columns.filter(column => column.accessor === id);
        return lista.length > 0 ? lista[0].filter : null;
    }

    const getOperatorDefault = (tipo) => {
        switch (tipo) {
            case TypeFilter.TEXT: return TypeOperator.CONTEM;
            case TypeFilter.NUMBER: return TypeOperator.IGUAL;
            case TypeFilter.DEFAULT: return TypeOperator.IGUAL;
            case TypeFilter.DATE: return TypeOperator.ENTRE;
            // case TypeFilter.BOOLEAN: return
            default: return undefined;
        }
    }

    const hasFiltro = (filtros, item) => {
        let hasFiltro = false;
        filtros.map(itemFiltro => {
            if (itemFiltro.nome === item.nome) {
                hasFiltro = true;
            }
            return itemFiltro;
        });
        return hasFiltro;
    }

    const limparFiltro = () => {
        filtros.map(filtro => { filtro.value = null; filtro.valueIni = ''; filtro.valueEnd = ''; return filtro; });
        setFiltros([]);
        if (onPesquisar) {
            onPesquisar([], fieldsVisible);
        }
    }

    const onChangeValor = (event, item) => {
        const valor = event.target.value;

        item.value = item.valueFilterApi ? item.valueFilterApi(valor) : valor;
        setFiltros(filtros.map((filtro) => filtro.field === item.field ? item : filtro));
    }

    const onChangeValorIni = (event, item) => {
        const valor = event.target.value;
        item.valueIni = item.valueFilterApi ? item.valueFilterApi(valor) : valor;
    }

    const onChangeValorEnd = (event, item) => {
        const valor = event.target.value;
        item.valueEnd = item.valueFilterApi ? item.valueFilterApi(valor) : valor;
    }

    const onChangeOperator = (event, item) => {
        item.operator = event.target.value;
        setHasChangeTipo(!hasChangeTipo);
        item.valueIni = '';
        item.valueEnd = '';
    }

    const onChangeCustomCombobox = (event, item) => {
        const opcao = JSON.parse(event.target.value);
        if (opcao.operator) {
            item.operator = opcao.operator;
        }

        if (opcao.tipo) {
            item.tipo = opcao.tipo;
        }
        item.value = opcao.value;
    }

    const pesquisar = (event) => {
        event.preventDefault();
        if (onPesquisar) {
            onPesquisar(filtros.filter(filter => filter.checked && (
                (filter.value && filter.value !== '') ||
                (filter.valueIni && filter.valueIni !== '' && filter.valueEnd && filter.valueEnd !== '')
            )), fieldsVisible);
        }
    }

    const [fieldsVisible, setFieldsVisible] = useState([]);

    const onChangeSelectField = (selected) => {
        setFieldsVisible(selected);
    };

    useEffect(() => {
        if (columns) {
            let lista = [];
            columns.forEach(column => {
                if (column.isVisible || column.isVisible === undefined) {
                    lista.push(column.Header);
                }
            });
            setFieldsVisible(lista);
        }
    }, [columns]) // eslint-disable-line

    const onChangeSelect = (event) => {
        const column = JSON.parse(event.target.value);
        const value = column.filter;
        value.id = column.accessor;

        setFiltro(value || undefined);
    }

    const remover = (event, item) => {
        event.preventDefault();
        const filtro = filtros.filter(filtro => item.id !== filtro.id);
        setFiltros(filtro);
    }

    const mostrarPicklist = (event) => {
        event.preventDefault();
        setMostraPicklist(!isMostraPicklist);
    }

    return (
        <>
            <div className={classes ? classes : "row"}>
                <div className='form-group col-4 col-md-3 col-lg-3 col-xl-2 input-group-sm'>
                    <label htmlFor='filtro' className='col-form-label'>Filtro</label>
                    <select
                        id="filtro"
                        className="custom-select"
                        // value={filtro}
                        onChange={onChangeSelect} >
                        {columns.map((column, index) => (
                            column.filter && <option key={index} value={JSON.stringify(column)}>{column.filter.nome}</option>
                        ))}
                    </select>
                </div>
                <div className="btn-group-vertical">
                    <button type="button" className="btn btn-icon-info btn-sm" onClick={adicionarFiltro} title="Adicionar Filtro">
                        <i className='fas fa-plus'></i>
                    </button>
                </div>
                <div className="btn-group-vertical ml-3">
                    <If test={selectedField}>
                        <button type="button" className="btn btn-icon-info btn-sm" onClick={mostrarPicklist} title="Organizar as colunas">
                            <i className='fas fa-table'></i>
                        </button>
                    </If>
                </div>
            </div>
            <div className='row'>
                <If test={filtros.length > 0}>
                    {filtros.map((item, index) => (
                        <Fragment key={index}>
                            <div className="row col-md-12">
                                <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-form-group input-group-sm">
                                    {
                                        item.checked &&
                                        <>

                                            <button type="button" className="btn btn-danger btn-sm" onClick={(event) => remover(event, item)} title="Excluir Filtro">
                                                <i className='fas fa-minus'></i>
                                            </button>
                                            <label className="control-label ml-3">{item.nome}</label>
                                        </>

                                    }

                                </div>
                                <If test={item.tipo !== TypeFilter.DATE && item.checked}>
                                    <div className='col-md-3 col-lg-3 col-xl-2 form-group input-group-sm'>
                                        {item.tipo === TypeFilter.DEFAULT && !item.isCustomCombobox && <TipoFiltroCombobox index={index} item={item} onChange={onChangeOperator} />}
                                        {item.tipo === TypeFilter.TEXT && !item.isCustomCombobox && <TipoFiltroTexto index={index} item={item} onChange={onChangeOperator} />}
                                        {item.tipo === TypeFilter.NUMBER && !item.isCustomCombobox && <TipoFiltroNumber index={index} item={item} onChange={onChangeOperator} />}
                                        {item.tipo === TypeFilter.BOOLEAN && !item.isCustomCombobox && <TipoFiltroBoolean index={index} item={item} onChange={onChangeOperator} />}
                                        {item.isCustomCombobox && <TipoFiltroCustomCombobox index={index} item={item} onChange={onChangeCustomCombobox} />}
                                    </div>
                                    <If test={item.operator === TypeOperator.ENTRE && !item.isCustomCombobox}>
                                        <div className='col-md-6 col-lg-6 col-xl-3 form-group input-group-sm'>
                                            <MaskedInput
                                                name={item.field} id={item.field}
                                                className='form-control'
                                                guide={false}
                                                mask={item.mask ? item.mask : MASK_NUMBER()}
                                                value={item.valueIni}
                                                onChange={(event) => onChangeValorIni(event, item)}
                                                autoComplete="off" />
                                        </div>
                                        <div className='col-md-6 col-lg-6 col-xl-3 form-group input-group-sm'>
                                            <MaskedInput
                                                name={item.field} id={item.field}
                                                className='form-control'
                                                value={item.valueEnd}
                                                onChange={(event) => onChangeValorEnd(event, item)}
                                                guide={false}
                                                mask={item.mask ? item.mask : MASK_NUMBER()}
                                                autoComplete="off" />

                                        </div>
                                    </If>
                                    <If test={item.operator !== TypeOperator.ENTRE && !item.isCustomCombobox}>
                                        <If test={item.tipo !== TypeFilter.NUMBER}>
                                            <div className='col-md-6 col-lg-6 col-xl-3 form-group input-group-sm'>
                                                <input name={item.field} id={item.field}
                                                    className='form-control' 
                                                    value={item.value || ""}
                                                    onChange={(event) => onChangeValor(event, item)} />
                                            </div>
                                        </If>
                                        <If test={item.tipo === TypeFilter.NUMBER}>
                                            <div className='col-md-6 col-lg-6 col-xl-3 form-group input-group-sm'>
                                                <MaskedInput
                                                    name={item.field} id={item.field}
                                                    className='form-control'
                                                    guide={false}
                                                    mask={item.mask ? item.mask : MASK_NUMBER()}
                                                    onChange={(event) => onChangeValor(event, item)}
                                                    value={item.value}
                                                    autoComplete="off" />
                                            </div>
                                        </If>
                                    </If>
                                </If>
                                <If test={item.tipo === TypeFilter.DATE && item.checked && !item.isCustomCombobox}>
                                    <div className="row col-md-9 form-group input-group-sm">
                                        {item.tipo === TypeFilter.DATE && <TipoFiltoDate index={index} item={item} />}
                                    </div>
                                </If>
                            </div>

                        </Fragment>
                    ))}
                </If>
                <If test={isMostraPicklist}>
                    <div className="row col-md-12">
                        <div className="col-md-12">
                            <h2 className="legend">Seleção de Campos</h2>
                            <div className="linha-legenda"></div>
                            <div className="col-md-12 col-lg-6 col-xl-6">
                                <SelectField columns={columns} onChange={onChangeSelectField} />
                            </div>
                        </div>
                    </div>
                </If>
                <If test={filtros.length > 0 || isMostraPicklist}>
                    <div className='row col-md-12 button-right mt-4 ml-0 mb-4'>
                        <button type="button" className="btn btn-outline-dark btn-sm mr-3" onClick={limparFiltro}>
                            <i className='fas fa-eraser'></i>Limpar
                        </button>
                        <button type="button" className="btn btn-info btn-sm" onClick={pesquisar}>
                            <i className='fas fa-search'></i>Pesquisar
                        </button>
                    </div>
                </If>
            </div>
        </>
    )
}

const TipoFiltroCustomCombobox = ({ index, item, onChange}) => {
    return (
        <select
            id={'tipoFiltroCustomCombobox' + index}
            className="custom-select"
            onChange={(event) => onChange(event, item)}
        >
            {item.opcoes.map((opcao, index) => <option key={index} value={JSON.stringify(opcao)}>{opcao.nome}</option>)};
        </select>
    )
}

const TipoFiltroCombobox = ({ index, item, onChange }) => {
    return (
        <select
            id={'tipo' + index}
            className="custom-select"
            value={item.operator}
            onChange={(event) => onChange(event, item)}
        >
            <option value={TypeOperator.IGUAL}>igual</option>
            <option value={TypeOperator.DIFERENTE}>diferente de</option>
        </select>
    )
}

const TipoFiltroTexto = ({ index, item, onChange }) => {
    return (
        <select
            id={'tipo' + index}
            className="custom-select"
            value={item.operator}
            onChange={(event) => onChange(event, item)}
        >
            <option value={TypeOperator.CONTEM}>contém</option>
            <option value={TypeOperator.NAO_CONTEM}>não contém</option>
        </select>
    )
}

const TipoFiltroNumber = ({ index, item, onChange }) => {
    return (
        <select
            id={'tipo' + index}
            className="custom-select"
            value={item.operator}
            onChange={(event) => onChange(event, item)}
        >
            <option value={TypeOperator.IGUAL}>igual a</option>
            <option value={TypeOperator.MAIOR_OU_IGUAL_QUE}>maior ou igual a </option>
            <option value={TypeOperator.MENOR_OU_IGUAL_QUE}>menor ou igual a </option>
            <option value={TypeOperator.ENTRE}>entre</option>
        </select>
    )
}

const TipoFiltroBoolean = ({ index, item, onChange }) => {
    return (
        <select
            id={'tipo' + index}
            className="custom-select"
            value={item.operator}
            onChange={(event) => onChange(event, item)}
        >
            <option value='sim'>sim</option>
            <option value='não'>não</option>
        </select>
    )
}

const TipoFiltoDate = ({ index, item }) => {
    const openCalendarInicial = (date) => {
        item.valueIni = item.valueFilterApi ?  item.valueFilterApi(date) : date;
    }

    const openCalendarFinal = (date) => {
        item.valueEnd = item.valueFilterApi ? item.valueFilterApi(date) : date;
    }

    return (
        <>
            <Datepicker id='dateInicial'
                date={item.dataInicial}
                typeDatepicker={item.isTime ? DatepickerType.DATE_TIME : DatepickerType.DATE}
                classes="col-6 col-lg-6 col-xl-4"
                callback={openCalendarInicial}
                isTime={item.isTime !== undefined ? item.isTime : false}
            />
            <Datepicker id='dateFinal'
                date={item.dataFinal}
                typeDatepicker={item.isTime ? DatepickerType.DATE_TIME : DatepickerType.DATE}
                classes="col-6 col-lg-6 col-xl-4"
                callback={openCalendarFinal}
                isTime={item.isTime !== undefined ? item.isTime : false}
            />
        </>
    )
}