import React, { Fragment, useMemo, useState } from 'react';
import { format } from 'date-fns';

import Painel from 'gp-painel';
import Table, { TypeOperator, TypeFilter, AlignType } from 'gp-table';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import ModalDetalheAuditoria from './modal';
import 'gp-step/dist/index.css'
import 'gp-form-filtro/dist/index.css';
import 'react-dual-listbox/lib/react-dual-listbox.css';

const Auditoria = ({urlAuditoria, message, aplicacao}) => {

  const formatarData = data => data === undefined ? '' : format((data), 'yyyy-MM-dd HH:mm:ss');

  const MASK_NUMBER = (limitInteger = null) => {
      return createNumberMask({
          prefix: '',
          includeThousandsSeparator: false,
          allowDecimal: false,
          integerLimit: limitInteger,
          allowNegative: false,
          allowLeadingZeroes: true,
          requireDecimal: false
      });
  }

  const removeMask = value => {return value !== '' ? value.replace(/[^0-9]/g, '') : value }

  const applyMaskDataHora = (dataString) => {
    dataString = removeMask(dataString);
    const mask = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/;
    dataString = dataString.length > 14 ? dataString.substring(0, 14).replace(mask, "$3/$2/$1 $4:$5:$6") : dataString.replace(mask, "$3/$2/$1 $4:$5:$6");
    return dataString;
  }

  const columns = useMemo(() =>
        [
            {
                Header: "Data e Hora",
                accessor: "data",
                Cell: ({ value }) => applyMaskDataHora(value),
                filter: {
                    tipo: TypeFilter.DATE,
                    nome: "Data e Hora",
                    operator: TypeOperator.ENTRE,
                    field: "data",
                    isTime: true,
                    valueFilterApi: formatarData,
                },
                alignItems: AlignType.CENTER,
            },
            {
                Header: "Transação",
                accessor: "transacao",
                filter: {
                    tipo: TypeFilter.TEXT,
                    nome: "Transação",
                    operator: TypeOperator.CONTEM,
                    field: 'transacao',
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: "Usuário",
                accessor: "usuario",
                filter: {
                    tipo: TypeFilter.TEXT,
                    nome: "Usuário",
                    operator: TypeOperator.CONTEM,
                    mask: MASK_NUMBER(),
                    field: 'usuario',
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: "Aplicação",
                accessor: "aplicacao",
                filter: {
                    tipo: TypeFilter.TEXT,
                    nome: "Aplicação",
                    operator: TypeOperator.CONTEM,
                    field: 'aplicacao',
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: "Entidade",
                accessor: "entidade",
                filter: {
                    tipo: TypeFilter.TEXT,
                    nome: "Entidade",
                    operator: TypeOperator.CONTEM,
                    field: 'entidade',
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: "Ação",
                accessor: "acao",
                filter: {
                    tipo: TypeFilter.TEXT,
                    nome: "Ação",
                    operator: TypeOperator.CONTEM,
                    field: 'acao',
                },
                alignItems: AlignType.CENTER
            },
            {
                Header: "Detalhe",
                width: '6%',
                accessor: "dados",
                Cell: ({ value }) => (
                    <button type="button" className="btn btn-outline-success btn-sm" title="Detalhe" onClick={(e) => detalhe(e, value)}>
                        <i className="fas fa-list-ul" />
                    </button>
                ),
                alignItems: AlignType.CENTER
            }

        ], []
    )

    const [openModal, setOpenModal] = useState(false);
    const [dados, setDados] = useState({});

    const detalhe = (event, item) => {
        if (event) event.preventDefault();

        setOpenModal(true);
        setDados(item);
    }

    const closeModal = () => {
        setOpenModal(false);
        setDados({});
    }

    return (
        <Fragment>
          <div className="col-12">
              <Painel titulo='Auditoria'>

                  <Table
                      columns={columns}
                      url={urlAuditoria + 'auditorias/filter'}
                      isPaginationMemory={false}
                      isFilterTable={false}
                      selectedField={true}
                      isFormFilter={true}
                      filtroLista={
                        {
                            field: "aplicacao" ,
                            operator: "LK",
                            value: aplicacao,
                            fieldType: "text"
                         }
                      }
                      message={message}
                  />

              </Painel>
          </div>
          <ModalDetalheAuditoria
              show={openModal}
              closeModal={closeModal}
              dados={dados}
          />
        </Fragment >
    );
};

export default Auditoria;
