import React, { useEffect, useState, Fragment } from 'react';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';


export const SelectField = ({ columns, onChange }) => {

    const [options, setOptions] = useState([]);

    useEffect(() => {
        let lista = [];
        let AllDefaultSelected = [];
        columns.forEach(column => {
            // const filter = column.filter;
            console.log('column', column.isVisible);
            if (column.isVisible || column.isVisible === undefined) {
                lista.push({
                    value: column.Header,
                    label: column.Header,
                });
                AllDefaultSelected.push(column.Header);
            }
        });

        setOptions(lista);
        setSelected(AllDefaultSelected);
        onChange(AllDefaultSelected);

    }, [columns]);

    const [selected, setSelected] = useState([]);

    const onChangeSelect = (selected) => {
        setSelected(selected);

        onChange(selected);
    };

    return (
        <>
            <DualListBox
                options={options}
                selected={selected}
                onChange={onChangeSelect}
                preserveSelectOrder={false}
                showOrderButtons={true}
            />

        </>
    )
}

